<template>
  <div class="page_container">
    <!-- 静态图片 -->
    <div class="about_banner">
      <img src="../../assets/image/about/join.jpg" alt />
    </div>
    <!-- 导航条 -->
    <div class="about_nav">
      <div class="about_nav_box">
        <ul class="about_nav_detail">
          <li>
            <router-link to="/about">公司简介</router-link>
          </li>
          <li>
            <router-link to="/prize">荣誉资质</router-link>
          </li>
          <li>
            <router-link to="/join">加入我们</router-link>
          </li>
          <li>
            <router-link to="/contact">联系我们</router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- 职位详情 -->
    <div class="position_details">
      <div class="back_btn">
        <i class="el-icon-back"></i>
        <router-link to="/join">全部职位</router-link>
      </div>
      <div class="web_details">
        <div class="web_title">高级开发工程师（前端）</div>
        <div class="web_duty_box">
          <p class="web_duty_title">岗位职责：</p>
          <p class="web_duty_details">
            1、负责公司软件产品的页面功能的开发；
            <br />2、配合后台开发人员实现产品界面和功能与后台的交互；
            <br />3、制作标准优化的代码，并增加交互动态功能；
            <br />4、参与需求分析，与后端程开发人员共同制定解决方案。
          </p>
        </div>
        <div class="web_require">
          <p class="web_duty_title">任职要求：</p>
          <p class="web_duty_details">
            1、本科以上学历，211/985院校优先<br>
            2、精通 HTML5/JavaScript/CSS3 等 Web 开发技术，有成功发布的作品<br>
            3、熟练应用canvas元素，要求有过前端三维开发经验，用过Three.js或其他三维框架<br>
            4、熟悉WEB标准，对表现与数据分离，HTML语义化等有深刻理解，对各种浏览器的兼容性有一定了解，并有相关问题的解决经验<br>
            5、熟悉前端工具链 Webpack、 ESLint、 Grunt 、 Gulp 、 LESS 、 SASS  <br>
            6、熟悉HTTP协议，熟练掌握HTML、CSS, 要熟悉es5标准，了解es6 <br>
            7、具备良好的服务意识、责任心、较强的学习能力、优秀的团队沟通与协作能力 <br>
            8、能够独立设计前端展现形式
          </p>
        </div>
        <div class="web_contact">
                    如有意向，可发送简历至：
                    <span class="blue_color">hr@bjblackhole.com，</span>
                    或联系：
                    <span class="blue_color"> 136 2850 8343.</span>
                </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  }
}
</script>

<style scope>
</style>
